import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/nav';
import Home from './components/Home/home';
import About from './components/About/about';
import Posts, { postRoutes } from './components/Posts/posts';
import Tags, { tagRoutes } from './components/Tags/tags';

export default function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/posts' element={<Posts/>} />
        {postRoutes}
        <Route path='/tags' element={<Tags/>} />
        {tagRoutes}
      </Routes>
    </div>
  );
}
