import './tags.css';
import { Route } from 'react-router-dom';
import { posts } from '../../allPosts';
import { BlogPreview } from '../Posts/posts';

const tags = [];
posts.forEach((post) => post.tags.forEach((tag) => {
    if(!tags.includes(tag)){tags.push(tag)}
} ));

function tagPage(tag){
    const filteredPosts = posts.filter((post) => post.tags.includes(tag));
    return(
        <div className="container">
            <h1>posts tagged with: {tag}</h1>
            {filteredPosts.map((post) => BlogPreview(post))}
        </div>
    );
}

export const tagRoutes = tags.map((tag) => <Route path={`/tags/${tag}`} element={tagPage(tag)} key={tag} />);
export default function Tags(){
    return(
        <div className="container">
            <h1 className="tagsTitle">Tags</h1>
            <ul>
                {tags.sort().map((tag) => 
                    <li key={tag}>
                        <a href={`/tags/${tag}`}>{tag}</a>
                    </li>
                )}
            </ul>
        </div>
    );
}