import './about.css';

export default function About(){
    return(
        <div className="container">
            <h1 className="aboutTitle">About</h1>
            <p className="aboutText">
                hi! I'm Grace. I am a software developer and web enthusiast. Sometimes I have thoughts! And this 
                is where they will live if I wish to make them public. :)
            </p>
            <p className="aboutText">
                It's likely that you arrived here from my <a href="https://graces-portal.net/">portal</a>.
                If you found this website some other way, welcome! Make yourself at home.
            </p>
        </div>
    );
}