import { 
    spotifywrapped23, linefont, cowculator1, cowculator2, cowculator3, cowculator4, graduated1, graduated2
} from "./assets/blog-images/blogimages";

export const posts = [
    {
        title: "started my job! & pondering other avenues",
        date: "May 30, 2024",
        path: "/posts/2024-05-30",
        tags: ["career", "life", "projects"],
        content:
        <div>
            <p>**This is an excerpt from an email I wrote to a friend**</p>
            <p>In terms of my life updates, I started that job at UCSD I mentioned. It's cool -- I'm in the Department of Enrollment Management (Financial Aid, Admissions, Registrar, and CalSOAP), on the Creative Services team. The team consists of an Interim Director (who was previously the Writer/Editor for all content), a Graphic Designer, Social Media Manager, Web Marketing Specialist, and Digital Communications Specialist (me). I'm enjoying it since it's a combination of marketing and coding; I get to write emails in HTML and CSS! Yay :) My position was supposed to end on May 15, but they are extending the position timeline out a bit, which I appreciate. They extended me out to September, so at least I have a paycheck during the summertime. That's good! But work is slowing down quite a bit now that admissions decisions have been released and we're almost done sending out scholarship awards. I'm actually responding to you while I'm at my desk right now since I finished all my work for the day. But I don't mind, I'm using this time to also embark on my newest ...endeavor.</p>
            <p>As I have stated before, I do still want to live in NYC at some point. I let go of that for a little bit, but the flame has been reignited! People keep asking me about the job search, and looking at jobs at UCSD, which would totally be possible and stable jobs. I know the people here like me and I could definitely get a full-time position here. Buttttt it's not really something I feel passionate about and I haven't really told anyone that I want to go in a completely different direction.</p>
            <p>I would love to learn how to create concert visuals, projection mapping, interactive art installations, or anything in that space. So today, I'm embarking on a journey to learn TouchDesigner (<a href="https://derivative.ca/" target="_blank" rel="noreferrer" >https://derivative.ca/</a>). It feels daunting because 1) it's a new, complicated thing, but 2) I want to actually become an expert at this tool. It's not difficult to partially learn something or become mediocre at any skill, but to become an expert or master in something takes time, effort, and commitment. But I think that would greatly benefit me in my future, especially if it's something I'd love to do as a job. I'm thinking maybe I can start learning this and hopefully get to the point where I could make some small visual graphics for some of my friends' band shows. That could help me learn how to actually put my work into production, build a portfolio, and make connections.</p>
            <p>There is also a Job Board on the <a href="https://derivative.ca/" target="_blank" rel="noreferrer" >derivative.ca</a> website with freelance opportunities or random jobs for people who know TouchDesigner. Those look fun! I don't know, I want to go down this route and see where it takes me. I feel like I could find a job that uses these skills in NYC, or make enough connections that I feel comfortable moving there and finding work. We'll see!</p>
            <p>Of course, there is the fear that I will be passionate about this "for about 4 hours," but I think if I continue envisioning a future where I use visual programming / graphics in my career, then I can stick with it. I just have to keep that motivation strong! I found this creator on TikTok who has been very inspiring thus far, and she actually made a video explaining how she created interactive visuals for an outdoor concert series: <a href="https://youtu.be/8zHPGbraW4Q?si=5wr9q7vqN7CAYlr7" target="_blank" rel="noreferrer" >https://youtu.be/8zHPGbraW4Q?si=5wr9q7vqN7CAYlr7</a></p>
            <p>So that's what I'm doing now. Hopefully, by the next time I email you, I will have updates related to this! Maybe some fun projects on the horizon! Who knows?</p>
        </div>
    },
    {
        title: "I got a job at UCSD!",
        date: "February 06, 2024",
        path: "/posts/2024-02-06",
        tags: ["career"],
        content:
        <div>
            <p>As far as the job search goes... I actually have news! I interviewed for a position at UCSD called Web Marketing Specialist...but then the position was offered to someone else with experience in Slate, the CRM software that UCSD uses. BUT then about a week later, one of the people who interviewed me informed me that there was a new opening within their department for a position building emails in Slate, and she described that it functions like a website in that there is a “front end” where text and graphics can be edited and a “back end” that is HTML coded.</p>
            <ul>
                <li>It's 3-4 months, part-time, and can be in-person, remote, or hybrid. I plan to go in-person and also continue working my other part-time job which I currently do on Mondays and Tuesdays.</li>
                <li>I'm very curious if this could potentially turn into a more long-term position, and even if it doesn't I'm excited to meet people and learn new things. :-)</li>
            </ul>
            <p>So... when I <i>didn't</i> get that job I interviewed for, I immediately turned to a new project that encapsulated like 5 other projects. But now that I have this upcoming position to look forward to, I feel a bit more balanced. I'm still continuing my other projects though! I definitely need to make sure I keep doing creative projects while working two different office / desk-work types of jobs. As usual, I feel happy every day with the average fluctuation of stress or whatever else. The past few weeks have been quite energetic though! Mostly in my own mind...so sometimes it manifests as creative energy and sometimes it's chaotic meticulousness to a fault.</p>
        </div>
    },
    {
        title: "new project ('Chure Thing) + Spotify Wrapped day!",
        date: "November 29, 2023",
        path: "/posts/2023-11-29",
        tags: ["projects", "music"],
        content:
        <div>
            <p>My latest project is now live: <a href="https://churething.com" target="_blank" rel="noreferrer" >https://churething.com</a>. I got a lot of brochures from my grandfather and have decided to archive them since there are some really old ones! I'm also going to continue collecting them and include modern ones as well. I'd like to create a collection that spans across many years. It's really fun to look at design aesthetics over the years!</p>
            <p>Lastly, I really want to make a project using the Spotify API. It'd be nice to create an application using React Native, which I've been wanting to learn since I already know React. I think it'd be cool to make something that functions both as a website and a mobile application. I'm not really sure what to create though -- I love the concept of being able to view your Spotify stats year-round (<a href="https://spotistats.app" target="_blank" rel="noreferrer">https://spotistats.app</a>), especially since Spotify Wrapped day is basically a holiday to me (which is today!!). I've attached my Spotify Wrapped, just in case you're curious.</p>
            <img style={{width: '30%'}} src={spotifywrapped23} alt="Spotify Wrapped. Top Artists: Grace Ives, The Strokes, Bloc Party, The Beatles, The Cribs. 31,776 Minutes Listened. Top Genre: Indie Rock." />
        </div>
    },
    {
        title: "my new obsession: the indie web movement",
        date: "November 28, 2023",
        path: "/posts/2023-11-28",
        tags: ["web"],
        content:
        <div>
            <p>**This is an excerpt from an email I wrote to a friend**</p>
            <p>My current obsession is learning about "web 1.0," the IndieWeb, and all associated topics. I even made a YouTube playlist! It's not too many videos, so not too much of a time/attention commitment. (<a href="https://youtube.com/playlist?list=PLXi1ul1U6DGY5mirDOkXTPLWFrPygoshy&si=BInmaGbtCF4PnU37" target="_blank" rel="noreferrer" >https://youtube.com/playlist?list=PLXi1ul1U6DGY5mirDOkXTPLWFrPygoshy&si=BInmaGbtCF4PnU37</a>). I like the IndieWeb concept of owning all your own content, especially in the world of social media where your content is posted on an app owned by a large company and they collect all your data...I think Google might know everything about me at this point. Which is good on one hand, because I secretly love targeted ads. However, it is an interesting thing to think about. I also love this appreciation for older style websites, before everything became so corporate and profit-focused. I love the personality in it!</p>
            <p>In the spirit of this, I have split my website into two: professional (<a href="https://grace-manning.com/" target="_blank" rel="noreferrer">https://grace-manning.com/</a>) and personal (<a href="https://graces-portal.net/" target="_blank" rel="noreferrer">https://graces-portal.net/</a>). The professional one matches the current style of websites, and it's primarily to be viewed by potential employers as I'm currently in the job application stage of my life. It definitely fits the clean/corporate vibe, which I tried to achieve without being too boring. I want to add some movement in there somehow. The "graces-portal" website is much more experimental and I can play around with fun ideas! I'm currently working on a page that has a bunch of CD images, and each one links to a playlist on Spotify.</p>
            <p>Here are some websites that have inspired me on this web journey:</p>
            <ul>
                <li><a href="https://www.cameronsworld.net" target="_blank" rel="noreferrer" >https://www.cameronsworld.net</a> (I could literally spend HOURS on this one -- turn up your volume and make sure the volume button in the top right isn't muted)</li>
                <li><a href="https://gifcities.org" target="_blank" rel="noreferrer" >https://gifcities.org</a> (collection of gifs from the Internet Archive)</li>
                <li><a href="https://lu.tiny-universes.net" target="_blank" rel="noreferrer" >https://lu.tiny-universes.net</a></li>
                <li><a href="https://sadgrl.online" target="_blank" rel="noreferrer" >https://sadgrl.online</a> (this person was part of creating the Yesterweb manifesto: <a href="https://yesterweb.org" target="_blank" rel="noreferrer" >https://yesterweb.org</a>)</li>
                <li><a href="https://loom.cafe" target="_blank" rel="noreferrer" >https://loom.cafe</a> (I can't even describe how OBSESSED I am with this design aesthetic)</li>
                <li><a href="https://www.jvt.me" target="_blank" rel="noreferrer" >https://www.jvt.me</a> (a more practical and content-focused personal website that I also enjoy!)</li>
            </ul>
        </div>
    },
    {
        title: "cool font alert: linefont",
        date: "November 07, 2023",
        path: "/posts/2023-11-07",
        tags: ["fonts"],
        content:
        <div>
            <img src={linefont} style={{width: '80%'}} alt="straight lines going up, down, across, and diagonally, but all connected" />
            <p>
                New font alert!!!! This one is called Linefont, available on <a href="https://fonts.google.com/specimen/Linefont" target="_blank" rel="noreferrer">Google Fonts</a>.
                It was created by Dmitry Ivanov, who created a similary cool font called <a href="https://fonts.google.com/specimen/Wavefont" target="_blank" rel="noreferrer">Wavefont</a>.
                <br/><br/>
                Linefont creates a point at a different height depending on the letter or symbol you type. When characters are next to each other, they are connected by a line. The space character will separate them with one unit of space. You can create some fun designs with this, and I'd recommend going into Google Fonts and testing it out with different preview texts.
                <br/><br/>
                You can also explore this font at the artist's <a href="https://dy.github.io/linefont/scripts/" target="_blank" rel="noreferrer">test page</a>, which allows you to select anywhere on the page and start typing to see how the lines change.
                <br/><br/>
                What's cool about Google Fonts is that you can implement this in any project for free, and you don't have to install anything for this to work. Time to test it out:
            </p>
            <p style={{fontFamily: "'Linefont'", fontSize: '36px'}}>
                iamtypingasentenceinlinefontrightnow. This is what it looks like when I include spaces! AAAAAAaaaaaaaaaa!
            </p>
            
            <p>
                " iamtypingasentenceinlinefontrightnow. This is what it looks like when I include spaces! AAAAAAaaaaaaaaaa! "
            </p>
        </div>
    },
    {
        title: "the Cowculator has launched!",
        date: "November 02, 2023",
        path: "/posts/2023-11-02",
        tags: ["projects"],
        content:
        <div>
        <p>The Cowculator is out on Google Play now! <a href="https://play.google.com/store/apps/details?id=com.gracemanning.cowculator" target="_blank" rel="noreferrer" >https://play.google.com/store/apps/details?id=com.gracemanning.cowculator</a></p>
        <p>I still need to upload it on the App Store but I'm having some Apple-specific issues (of course). I bought an old refurbished Macbook about two years ago purely for the purpose of using XCode on it. Well, I've been working on the Cowculator off-and-on for about two years now. I tried to export it last month, and it said I need to target a newer version of iOS if I want to upload it to the App Store. Okay, sure. So I try to target a new version of iOS. Oops! I need to upgrade my version of XCode in order to do that. Oops! I need to upgrade the version of MacOS running on this laptop. OOPS! The hardware doesn't support that upgrade. Cool!!</p>
        <p>So...maybe I need to buy a newer refurbished Macbook (as inexpensive as possible) so that I can make this happen. Or figure out some sort of workaround, idk. It's quite a frustrating task! Either way, it's still exciting that the Cowculator is up on at least one platform. Thank goodness!!</p>
        <p>I've also already turned my attention to a new project, which should hopefully actually make me some money if all goes as planned. I will share it once I have a link or something to actually send to you.</p>
        </div>
    },
    {
        title: "don't ask DALL-E to write words",
        date: "September 15, 2023",
        path: "/posts/2023-09-15",
        tags: ["AI", "silly"],
        content:
        <div>
        <p>So I created a logo for the Cowculator app last year, but it's not my favorite thing and I feel like I can do better in terms of the design. I was curious if maybe I could get inspiration from AI-generated images, so I created a DALL-E 2 account and bought some credits to just test it out. Here's the prompt I entered: logo for a cow-themed calculator "cowculator"</p>
        <p>Lesson learned: incorporating text into AI-generated images could turn out very silly...</p>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <img style={{width: '24%'}} src={cowculator1} alt="AI-generated cartoon-style cow with a calculator body." />
            <img style={{width: '24%'}} src={cowculator2} alt="AI-generated cartoon-style cow with a calculator body." />
            <img style={{width: '24%'}} src={cowculator3} alt="AI-generated cartoon-style cow with a calculator body." />
            <img style={{width: '24%'}} src={cowculator4} alt="AI-generated cartoon-style cow with a calculator body." />
        </div>
        </div>
    },
    {
        title: "sleep issues & post-grad rambling",
        date: "July 14, 2023",
        path: "/posts/2023-07-14",
        tags: ["sleep", "life"],
        content:
        <div>
            <p>**This is an excerpt from an email I wrote to a friend**</p>
            <p>1) Sleep-related things:</p>
            <p>I haven't really researched lucid dreams, I just heard about them at some point and realized I have them sometimes (and by that I mean...probably once a year. It's very minimal!). Unfortunately, my lucid dreams are typically a good dream that turns into a nightmare, and I'm just conscious but can't control the dream. I think one time a lucid dream transitioned into sleep paralysis, which lasted about 10 seconds while my dream played "on a loop", but it's hard to say. Sometimes the dream world and real world are really hard to separate!</p>
            <p>I keep wanting to start a dream journal, but right now I just have a stack of random scrap papers where I've scribbled different dreams. It honestly looks like the ramblings of an insane person. Just a bunch of random drawings with phrases like "Candace Cameron Bure showing me her childhood home" or "ferris wheel with bad vibes".... Also, it's very interesting how when I think of one dream, it helps me remember other ones. Sometimes I feel like I have dream "eras," where some dreams within a certain time period exist within the same world. I don't even know how to describe it. ALSO one thing that held me back from creating a dream journal was deciding how to organize it, but I finally figured that out. Now I just need to actually write things in it! I am also held back by perfectionism and wanting to perfectly draw & describe a dream, but I just need to let that go. The only person it needs to make sense to is...myself.</p>
            <p>As for the <a href="https://www.youtube.com/watch?v=-s0CobTi7aM">video of No-Face saying "ah"</a>... I think I made that sound when I had (what I think was) my first sleep paralysis experience around 7 years old. Then I don't think I had any others until senior year of high school. Luckily I don't feel the weight on my chest / difficulty breathing that many people experience; I just have very strong visual and auditory hallucinations. After my first visual hallucination, I started closing my eyes during sleep paralysis and now I usually just have auditory hallucinations that are often accompanied by a "dream" because my brain doesn't know where to put the visual hallucinations. Sometimes I just hear really loud silence, like what you hear when you yawn. And sometimes I hear overlapping sounds like drums, laughter, crying, talking, etc. all at once. It's very strange! One time I heard just the sound of squeezing a Windex bottle for like 10 seconds...I didn't really understand that.</p>
            <p>As for my sleep walking, it's only happened a couple times and it was just in junior year of high school. I would wake up at 2am, look at my clock and read it as 5:30am, then get up and get ready for school. I'd go to the bathroom, wash my face, put in my contacts, and get dressed. Once I open the bathroom door (which makes a loud sound), it would wake me up and I'd realize it's the middle of the night and everyone was asleep. It was so weird. I guess it was probably stress-related? Like I was anxious about getting ready for school / being late / missing class. I can remember it and remember what I saw, but my brain was totally off while I was getting ready for class. I even have a visual memory of the clock saying 2am, but my brain just decided to understand that as 5:30am.</p>
            <p>I find the edge of sleep to be a very strange place. I get a lot of "half dreams" in that space, usually where I'm awake and having a thought and then it turns into a dream. Or some scene that's very quick.</p>
            <p>When I took my final exams, I actually started falling asleep during one! As I was nodding off, I had a really quick "dream" where I saw a black rotary phone and heard a loud old telephone-style ring that woke me back up, but the room was completely silent. It was strange.</p>
            <p>I can also use that "edge of sleep" time to sense if I might have sleep paralysis that night. If I am laying in bed and can feel my body falling asleep, but my brain is still wide awake, that's usually a sign. I usually get up and get a glass of water or roll over to wake my body back up and try again. Things need to be in sync or else it'll be a weird night!! My roommate thinks that's super weird, since she has never felt her body falling asleep "out of sync" from her brain. I don't know how to describe it.</p>
            <p>Anyways, I did talk to a sleep doctor about these things. He was pretty quick in the consultation, but he did recommend I take a sleep test at some point. I should schedule it, but I keep putting it off. I want to see how my sleep changes when I'm not in school anymore. It's definitely worsened by a bad sleep schedule, and also by stress. So far I'm sleeping very well, but I'll see how it goes!</p>
            <p>2) VenusHacks</p>
            <p>VenusHacks 2023, the hackathon that I was Co-President of, went really well! I've never been Co-President of anything, that was scary but cool. I definitely learned a lot! It was May 26-28 and I filmed a bunch of clips during the event, but I still haven't edited the full video yet. Oopsie.</p>
            <p>3) Graduating</p>
            <p>I graduated on June 20th! With a degree in Computer Science!! It definitely wouldn't have been possible without Mrs. Esper introducing me to ThoughtSTEM or, more importantly, your many hours of teaching me coding concepts!! So...thank you!!! College was hard and sucked at times but it was also the best 4 years of my life so far! So that's cool.</p>
            <p>4) Peru</p>
            <p>My mom, my brother, and I went to Peru! I took so many pictures, but the best ones are on my Instagram. Do you still have an account? If so, you can view it on www.instagram.com/gracebmanning. The Peru story highlight has some great pictures and videos. If not, then let me know and I'll send you a screen recording of it. Peru was so cool!!</p>
            <p>5) Next Steps</p>
            <p>Okay so the million dollar question now that I've graduated is...what's next? I'm taking this summer off, just working 2 days a week for my neighbor's small kinda-biotech biz and taking the rest of the days to work on projects, apply to jobs, and socialize. So far, I've been very successful at the socializing part!!</p>
            <p>I have a lot of projects that I want to work on / finish before I start applying to jobs, but I know I need to just start applying so I can move forward with my life instead of putting it off until I'm "ready." (News flash: I will always have unfinished projects and I have to accept that). Anyways, I am mostly interested in front-end web developer positions (will also look for app developer positions). I want to aim for industries like art, music, fashion, and marketing. I realized there are some cool marketing agencies out there that do some combination of branding, graphic design, and website development as package deals for clients. That sounds fun!!</p>
            <p>Also, my friend and I are actually going on a trip August 1-8 to explore Boston and NYC (mostly Brooklyn). I am considering both of those as potential places to live, so I want to visit and make sure I actually like them before I move there. But that's where I'll be aiming my job hunt!</p>
        </div>
    },
    {
        title: "I graduated!",
        date: "June 20, 2023",
        path: "/posts/2023-06-20",
        tags: ["school", "career"],
        content:
        <div>
            <p>
                Here's a quick update: <b>I graduated with a degree in Computer Science from UC Irvine!</b> I am
                incredibly grateful for the four years spent here, and the amazing organizations I was involved
                with. Of course, shoutout to WICS <a href="https://wics.ics.uci.edu/" target="_blank" rel="noreferrer">(Women in
                    Information & Computer Sciences)</a>, which brought me opportunities I never would've
                thought possible! I am also very grateful for my amazing roommate of four years, Emmy! College
                wouldn't have been possible without you!! The 2022-2023 school year also brought lots of cool
                new people into my life through being a part of the UCI Fashion Collective and <a
                    href="https://venushacks.com/" target="_blank" rel="noreferrer">VenusHacks 2023</a>. Feeling: grateful!!
            </p>
            <br/>
            <div>
                <img style={{width: `30%`}} src={graduated1} alt="Grace wearing a grad cap and gown, pointing to a diploma holder with a post-it note inside that says 'diploma.'" />
                <img style={{width: `30%`}} src={graduated2} alt="Grace wearing a grad cap and gown, smiling and holding a diploma holder with a post-it note inside that says 'diploma.'" />
            </div>
        </div>
    },
    {
        title: "life in college & the job search",
        date: "February 14, 2023",
        path: "/posts/2023-02-14",
        tags: ["school", "career"],
        content:
        <div>
            <p>**This is an excerpt from an email I wrote to a friend**</p>
            <p>As for what's been going on with me lately, it's been an interesting school year so far. I'm still in WICS (<a href="https://wics.ics.uci.edu/" target="_blank" rel="noreferrer">https://wics.ics.uci.edu/</a>), although not on the board anymore since I wanted to take it easy for my senior year. However, the WICS Co-Presidents asked if I could be a Co-President for VenusHacks (<a href="https://venushacks.com/" target="_blank" rel="noreferrer">https://venushacks.com/</a>) this year, and I was hesitant at first but ultimately said yes. It's a hackathon that's put on each year as a collaboration between WICS and Hack at UCI (<a href="https://hack.ics.uci.edu/" target="_blank" rel="noreferrer">https://hack.ics.uci.edu/</a>). So far so good! Never thought I'd be the Co-President of...anything! It's quite the learning experience, since I've never done event planning, but it's really cool. My experience has always been limited to marketing (see: WICS Marketing Committee '20-21, WICS Marketing Chair '21-22, VenusHacks Marketing Committee '22), so this is definitely expanding my knowledge of what goes into planning a hackathon.</p>
            <p>Academics have been pretty good so far. Fall quarter was easy -- my 3 classes were on software design, web development, and Java. This quarter, I'm taking 3 project courses...which was an accident. I thought I was taking 2 project courses, but I went to an advisor yesterday because I need to drop a class, and she said it makes sense since I'm in three project courses. I nearly gasped because I genuinely didn't know that one of my classes was a project course, and that's because it's within a different department so it doesn't count as a project course for Comp Sci majors. It's an Informatics project course...so, slightly different topics but similar workload. Anyways, on Sunday night I basically came to the conclusion that I need to drop one of my courses because it <b>sucks</b> and also I'm starting to lose sleep over it. Not a great sign! Luckily, there are courses I can take next quarter to make up for it. *To clarify: the course runs concurrently with a grad course, so there are grad students in my class and the content is the same for everyone. The only difference is that undergrads are allowed to work with a partner in the class. The class has about 15 people in it at this point, most of which are grad students, and the rest are undergrad students who probably want to go into database optimization research or have dreams of becoming a database administrator some day. Basically, not my people and not my topic of interest! The only reason I'm in it is because it fulfilled a course requirement I needed, but I truly have no idea what's going on in the class at this point. I'll be okay though -- I can drop it next week and then bounce back next quarter. Not the first time I've done this!</p>
            <p>In terms of the job search, I've had pretty promising conversations with a recruiter at Slalom, in their Boston office. She's been very eager to get me started with the interview process, but they have been experiencing delays due to the "economic situation" -- her words. Weirdly enough, I'm not worried at all. Maybe I'm too tired to feel excited about trying to get a job! She was saying that my start date would be mid-June, and that already didn't sit right with me because I graduate June 20th and would like a break before jumping straight into work. I think from here on out, I'm going to look for jobs that start in September. I definitely need a summer off before doing any ounce of work.</p>
            <p>While I do still want to go into front-end or full-stack web developing, I've also been trying to look at other career options that would allow me to explore my creative interests (i.e., fashion, art, filmmaking). I saw this TikTok video a while back and haven't stopped thinking about it. <a href="https://www.tiktok.com/@muhtanya/video/7077642610881629486" target="_blank" rel="noreferrer">https://www.tiktok.com/@muhtanya/video/7077642610881629486</a></p>
            <p>I would love to do this as a career somehow, whether it's being commissioned by musicians to create concert visuals, or creating visuals for runway shows, who knows! It's very interesting to me... Here's one of an interactive installation that the same person did for a music show: <a href="https://www.tiktok.com/@muhtanya/video/7184491478000110894" target="_blank" rel="noreferrer">https://www.tiktok.com/@muhtanya/video/7184491478000110894</a></p>
            <p>I think those are my updates for now! I have a video idea that I would still like to do, I just haven't found the time to do it. Once I drop my unfavorable class, hopefully I can have time to work some creative things into my week! One of my goals for 2023 was to do at least one creative thing per week, whether that be crochet, embroidery, video editing, etc. Anything works! Just has to be something that isn't school/club/work related.</p>
        </div>
    }
]