import './post.css';
import { posts } from '../../allPosts';
import { Route } from "react-router-dom";
import { BsEnvelopeHeart } from "react-icons/bs";

// INDIVIDUAL BLOG POST PAGE
function BlogPost(post, index){
    let prev;
    let next;
    if(index === 0){
        let prevPost = posts[index+1];
        prev = <p>prev: <a href={prevPost.path}>{prevPost.title}</a></p>;
        next = <p>next: none</p>;
    } else if(index === posts.length-1){
        prev = <p>previous: none</p>;
        let nextPost = posts[index-1];
        next = <p>next: <a href={nextPost.path}>{nextPost.title}</a></p>;
    } else{
        let prevPost = posts[index+1];
        let nextPost = posts[index-1];
        prev = <p>prev: <a href={prevPost.path}>{prevPost.title}</a></p>;
        next = <p>next: <a href={nextPost.path}>{nextPost.title}</a></p>;
    }

    const tagsList = post.tags.sort().map((tag) => 
        <li key={tag}>
            <a href={`/tags/${tag}`}>{tag}</a>
        </li>
    )

    return(
        
        <div className="container">
            <div className="blogContainer">
                <h1>{post.title}</h1>
                <h2>{post.date}</h2>
                <p className="blogTags">tags: <ul>{tagsList}</ul></p>
                {post.content}
            </div>
            <div className="blogFooterLinks">
                <span className="footerLink">{prev}</span>
                <span className="footerLink">{next}</span>
            </div>
        </div>
    );
}

// ROUTES FOR ALL BLOG POSTS
export const postRoutes = posts.map((post, index) => <Route path={post.path} element={BlogPost(post, index)} key={post.title} />);

// BLOG PREVIEW TILE
export function BlogPreview(post){
    return(
        <div className="blogPreview" key={post.title}>
            <BsEnvelopeHeart className="envelopeIcon" />
            <div>
                <h2>{post.title}</h2>
                <h3>{post.date}</h3>
                <a href={post.path}>read here!</a>
            </div>
        </div>
    );
}

// POSTS PAGE - SHOWING ALL POSTS
export default function Posts(){
    return(
        <div className="container">
            <h1>All Posts</h1>
            {posts.map((post) => BlogPreview(post))}
        </div>
    );
}