import './nav.css';
import star from '../../assets/star.gif';

export default function Navbar(){
    return(
        <nav className="nav">
            <span className="navHeader">
                <img className="navStar" src={star} alt="purple glowing star" />
                <h1>grace's blog</h1>
                <img className="navStar" src={star} alt="purple glowing star" />
            </span>
            <ul className="navItems">
                <li>
                    <a href="/">home</a>
                </li>
                <li>
                    <a href="/about">about</a>
                </li>
                <li>
                    <a href="/posts">all posts</a>
                </li>
                <li>
                    <a href="/tags">browse by tags</a>
                </li>
            </ul>
        </nav>
    )
}