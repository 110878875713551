import './home.css';
import ferretWrite from '../../assets/ferretwrite.gif';
import poohWrite from '../../assets/poohwrite.gif';
import girlWrite from '../../assets/girlWrite.gif';

export default function Home(){
    return(
        <div className="container">
            <h2>welcome to...</h2>
            <h1 className="homeTitle">grace's blog!</h1>
            <br/>
            <p>real life images of me writing my posts:</p>
            <div>
                <img src={ferretWrite} alt="ferret writing at a desk" />
                <img src={poohWrite} alt="winnie the pooh laying down, writing with a quill and ink" />
                <img src={girlWrite} alt="girl writing at a desk" />
            </div>
            <p>(sourced from <a href="https://gifcities.org/" target="_blank" rel="noreferrer">gif cities</a>)</p>
        </div>
    );
}